.contact-page {
    background-color: #000;
    color: #fff;
    padding: 2rem;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .contact-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .contact-intro {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #bbb;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #222;
    color: #fff;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color:#9654c6 ;
    outline: none;
  }
  
  .submit-button {
    padding: 0.75rem 1.5rem;
    background-color: #9654c6;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .submit-button:hover {
    background-color: #9654c6;
  }
  .contact-address {
    background-color: #111; 
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    color: #bbb;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }