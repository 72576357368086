.blog-detail-page {
    background-color: #000; 
    color: #fff;
    padding: 3rem 2rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  
  .blog-detail-image {
    width: 70%; 
    max-width: 400px;  
    height: auto; 
    border-radius: 10px;
    margin: 0 auto 2rem; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .blog-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #9654c6; 
  }
  
  .blog-date {
    font-size: 1rem;
    color: #bbb;
    margin-bottom: 1rem;
  }
  
  .blog-description {
    font-size: 1.2rem;
    color: #ddd;
    margin-bottom: 2rem;
  }
  
  .blog-content {
    font-size: 1rem;
    line-height: 1.8; 
    color: #ccc;
    text-align: left; 
    max-width: 800px; 
    margin: 0 auto; 
  }
  
  .blog-content p {
    margin-bottom: 1.5rem; 
  }
  