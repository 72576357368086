.all-products-page {
    padding: 40px 20px;
    text-align: center;
    background-color: #000; 
    color: #fff;
    font-family: 'Poppins', sans-serif;
  }
  
  .all-products-page h1 {
    font-size: 2.5rem;
    color: #9654c6; 
    margin-bottom: 10px;
  }
  
  .all-products-page p {
    font-size: 1.2rem;
    color: #ccc;
    margin-bottom: 30px;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; 
  }
  
  .product-item {
    background-color: #1e1e1e; 
    border-radius: 10px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); 
    padding: 20px;
    width: 300px; 
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
  }
  
  .product-item:hover {
    transform: translateY(-10px); 
    box-shadow: 0 8px 15px rgba(150, 84, 198, 0.5); 
  }
  
  .product-item h2 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .product-item p {
    font-size: 1rem;
    color: #bbb;
    margin-bottom: 20px;
    flex-grow: 1; 
  }
  
   .product-link {
    display: inline-block;
    background-color: #9654c6; 
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px; 
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .product-link:hover {
    background-color: #7a41a6; 
    text-decoration: none;
  }
  
  