.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #111;
}

.logo img {
  height: 80px;
}

.hamburger {
  display: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links li a:hover {
  color: #6C63FF;
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #111;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.nav-links.open li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: block;
  }
}

