.footer {
  background-color: #111; 
  color: #fff; 
  padding: 2rem 2rem; 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: center; 
  border-top: 1px solid #333; 
  margin-top: 2rem; 
}

.footer-content {
  text-align: left; 
  flex: 1; 
  padding: 0 1rem; 
}

.footer-content h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.footer-content p {
  font-size: 0.9rem; 
  color: #bbb;
}

.footer-links {
  text-align: center; 
  flex: 1; 
}

.footer-section-title {
  font-size: 1.2rem;
  color: #9654c6; 
  margin-bottom: 0.5rem;
}

.footer-links ul {
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
  gap: 1rem; 
  list-style: none; 
  margin: 0;
  padding: 0;
}

.footer-links li {
  display: inline;
}

.footer-socials {
  text-align: right; 
  flex: 1; 
  padding: 0 1rem; 
}

.footer-socials .social-icons {
  display: flex; 
  justify-content: flex-end; 
  gap: 1rem; 
  margin-top: 0.5rem;
}

.footer-socials a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-socials a:hover {
  color: #6C63FF; 
}
