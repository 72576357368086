.blog-page {
  background-color: #000;
  color: #fff;
  padding: 3rem 2rem;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #9654c6;
}

.blog-intro {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #ccc;
}

.blog-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem; 
  justify-content: center;
  align-items: flex-start;
  padding: 0; 
}

.blog-card {
  background-color: #292929;
  padding: 1.5rem; 
  border-radius: 10px;
  width: 300px; 
  max-width: 100%; 
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0; 
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(150, 84, 198, 0.6);
}

.blog-card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 0; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.blog-card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #9654c6;
}

.blog-card-date {
  font-size: 0.9rem;
  color: #bbb;
  margin-bottom: 0.3rem;
}

.blog-card-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #ddd;
  line-height: 1.5;
}

.blog-read-more {
  padding: 0.8rem 1.5rem;
  background-color: #9654c6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  align-self: center;
}

.blog-read-more:hover {
  background-color: #7a41a6;
  transform: scale(1.05);
}

